import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"

export default props => (
  <>
    <h2>Previously on Layerized</h2>
    <div className="previously">
      <StaticQuery
        query={graphql`
          query {
            allContentfulPost(
              sort: { fields: [createdAt], order: DESC }
              limit: 4
            ) {
              edges {
                node {
                  id
                  title
                  slug
                  description
                }
              }
            }
          }
        `}
        render={data =>
          data.allContentfulPost.edges.map(post => (
            <Link key={post.node.id} to={`/post/${post.node.slug}/`}>
              <h3>{post.node.title}</h3>
              <p>{post.node.description}</p>
            </Link>
          ))
        }
      />
    </div>
  </>
)
