import React, { Component } from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import options from "../contentfulOptions"
import Layout from "../components/layout"
import Previously from "../components/previously"
import SEO from "../components/seo"

class PageTemplate extends Component {
  render() {
    const page = this.props.data.contentfulPage
    return (
      <Layout>
        <SEO
          title={page.title}
          description={page.description}
          keywords={page.tags || []}
        />
        <h1>{page.title}</h1>
        <section>
          {documentToReactComponents(page.content.json, options)}
        </section>
        {this.props.location.pathname === "/" && <Previously />}
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query($id: String!) {
    contentfulPage(id: { eq: $id }) {
      id
      slug
      title
      tags
      description
      content {
        json
      }
    }
  }
`

export default PageTemplate
